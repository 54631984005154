import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";
import _ from "lodash";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonToolbar,
  Content,
  Drawer,
  Form,
  IconButton,
  Message,
  Schema,
  Table,
  toaster,
  Tooltip,
  Whisper,
} from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { deleteSocialAccount, saveSocialAccount } from "../../api/social.api";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { Field } from "../../components/FormInputs";
import { useSocialAccounts } from "../../lib/use-social-accounts";

const defaultFormValue = { email: "", username: "", password: "" };

const TwitterHackedTab: React.FC = () => {
  const { accountId = "" } = useParams();

  const formRef = useRef();

  const { data, refetch } = useSocialAccounts({ accountId, provider: "TWITTER_HACKED" });
  const [loading, setLoading] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [formValue, setFormValue] = useState(_.cloneDeep(defaultFormValue));
  const [formError, setFormError] = useState(_.cloneDeep(defaultFormValue));

  // delete
  const [deleteId, setDeleteId] = useState("");
  const deleteItem = async () => {
    if (!deleteId) {
      return;
    }

    try {
      await deleteSocialAccount(accountId, deleteId);
      setDeleteId("");
      refetch();
      toaster.push(
        <Message type="success" header="Success" showIcon>
          Social account deleted!
        </Message>
      );
    } catch (err) {
      toaster.push(<Message type="error">Error!!!!</Message>);
      console.log(err);
    }
  };

  const submitForm = async () => {
    // @ts-ignore
    if (!formRef?.current.check()) {
      return;
    }

    setLoading(true);

    try {
      // @ts-ignore
      await saveSocialAccount(accountId, {
        provider: "TWITTER_HACKED",
        name: formValue.username,
        config: {
          email: formValue.email,
          username: formValue.username,
          password: formValue.password,
        },
      });

      setEditorOpen(false);
      refetch();
      setFormValue(_.cloneDeep(defaultFormValue));
      toaster.push(
        <Message type="success" header="Success" showIcon>
          Social account added!
        </Message>
      );
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const model = Schema.Model({
    email: Schema.Types.StringType().isRequired("Please enter an email"),
    username: Schema.Types.StringType().isRequired("Please enter a username"),
    password: Schema.Types.StringType().isRequired("Please enter a password"),
  });

  const onEdit = (rowData: any) => {
    // @ts-ignore
    setFormValue(convertKeysToDotNotation(_.cloneDeep(rowData)));
    setEditorOpen(true);
  };

  return (
    <Content>
      <IconButton
        icon={<PlusIcon />}
        appearance="primary"
        color="blue"
        loading={loading}
        onClick={() => {
          setFormValue(_.cloneDeep(defaultFormValue));
          setEditorOpen(true);
        }}
      >
        Add Twitter Account
      </IconButton>

      <Drawer size="lg" placement="right" backdrop="static" open={editorOpen} onClose={() => setEditorOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>Twitter Account</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setEditorOpen(false)}>Cancel</Button>
            <Button onClick={() => submitForm()} appearance="primary" loading={loading}>
              Confirm
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            // @ts-ignore
            ref={formRef}
            onChange={(newValues) => {
              const values = {};
              Object.keys(newValues).forEach((key) => {
                _.set(values, key, newValues[key]);
              });
              // @ts-ignore
              setFormValue(values);
            }}
            // @ts-ignore
            onCheck={setFormError}
            formValue={formValue}
            model={model}
            fluid
          >
            <Field
              // @ts-ignore
              name="email"
              label="Email"
              type="email"
              error={formError.email}
            />

            <Field
              // @ts-ignore
              name="username"
              label="Username"
              error={formError.username}
            />

            <Field
              // @ts-ignore
              name="password"
              label="Password"
              error={formError.password}
            />
          </Form>
        </Drawer.Body>
      </Drawer>

      <Table
        data={data}
        loading={loading}
        autoHeight
        bordered
        style={{ marginTop: 20 }}
        onRowClick={(rowData, e) => {
          // @ts-ignore
          if (["svg", "path", "button"].includes(e.target.tagName.toLowerCase())) {
            return;
          }
          onEdit(rowData);
        }}
      >
        <Column>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="name" />
        </Column>

        <Column flexGrow={1}>
          <HeaderCell>Status</HeaderCell>
          <Cell dataKey="status" />
        </Column>

        <Column width={200}>
          <HeaderCell>Actions</HeaderCell>
          <Cell>
            {(rowData) => (
              <ButtonToolbar>
                <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>Delete</Tooltip>}>
                  <IconButton
                    size="xs"
                    icon={<TrashIcon />}
                    appearance="primary"
                    color="red"
                    onClick={() => setDeleteId(rowData.id)}
                  />
                </Whisper>
              </ButtonToolbar>
            )}
          </Cell>
        </Column>
      </Table>

      <ConfirmDialog
        open={deleteId ? true : false}
        onCancel={() => {
          setDeleteId("");
        }}
        onConfirm={() => deleteItem()}
      />
    </Content>
  );
};

export default TwitterHackedTab;
